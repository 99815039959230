<template>
  <div v-if="item">
    <v-tooltip top open-delay="500">
      <template v-slot:activator="{ on }">
        <v-chip small outlined style="font-size: 11px; border:none">
          <v-icon :color="statusColor(item)" v-on="on" small left>
            {{ item == "ROAD" ? "local_shipping" : item == "RAIL" ? "train" : item == "AIR" ? "flight" : "directions_boat" }}
          </v-icon>
          {{ getTextValue(item) }}
        </v-chip>
      </template>
      <span style="font-size: 12px">{{ getTextValue(item) }}</span>
    </v-tooltip>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    item: null,
    group: null,
    statuses: [
    { name: "Ocean", value: "OCEAN", icon: "directions_boat", color: "blue-grey" },
      { name: "Road", value: "ROAD", icon: "local_shipping", color: "light-green" },
      { name: "Rail", value: "RAIL", icon: "train", color: "amber" },
      { name: "Air", value: "AIR", icon: "flight", color: "blue lighten-2" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
  },
  methods: {
    getTextValue(status) {
      if (status) {
      return status
      } else {
        return "Unknown";
      }
    },
    statusColor(item) {
      if (item) {
        let find = this.statuses.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
  },
};
</script>
  
  <style>
</style>